
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import MyCalendar from "@/views/Classify/components/Calender/MyCalendar.vue";
import { Row, Col, Button } from "vant";
@Component({
  components: {
    MyCalendar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
  },
})
export default class ScreenTime extends Mixins(Mixin) {
  public timeType: any[] = [
    { id: "all", value: "全部" },
    { id: "week", value: "一周内" },
    { id: "month", value: "一月内" },
    { id: "weekend", value: "本周末" },
    { id: "appoint", value: "指定时间" },
  ];
  public selectT = this.isChannelDistribution ? "" : "month";
  public startTimeStamp? = 0;
  public endTimeStamp? = 0;
  public timeValue = "";
  get isWeekend(): boolean {
    return new Date().getDay() == 0 || new Date().getDay() == 6 ? true : false;
  }
  Reset(): void {
    // 重置
    delete this.startTimeStamp;
    delete this.endTimeStamp;
    this.timeValue = "";
    this.selectT = "all";
  }
  Onsubmit(): void {
    // 完成
    let form = {
      startTimeStamp: 0,
      endTimeStamp: 0,
      type: "",
    };
    if (this.selectT === "appoint" && !this.timeValue) {
      form.startTimeStamp = new Date(new Date().toLocaleDateString()).getTime();
      form.endTimeStamp =
        new Date(new Date().toLocaleDateString()).getTime() +
        (60 * 60 * 24 - 1) * 1000;
      form.type = this.selectT;
      this.$emit("selectTime", form);
    } else {
      this.onCheckTime(this.selectT);
      form.startTimeStamp = this.startTimeStamp!;
      form.endTimeStamp = this.endTimeStamp!;
      form.type = this.selectT;
      this.$emit("selectTime", form);
    }
    this.startTimeStamp = 0;
    this.endTimeStamp = 0;
  }
  onclick(date: string): void {
    this.timeValue = date;
    this.startTimeStamp = new Date(this.timeValue).getTime();
    this.endTimeStamp =
      new Date(this.timeValue).getTime() + (60 * 60 * 24 - 1) * 1000;
  }

  onCheckTime(id: string): void {
    this.selectT = id;
    if (this.selectT == "week") {
      this.startTimeStamp = new Date().getTime();
      this.endTimeStamp = new Date().getTime() + 3600 * 1000 * 24 * 7;
    } else if (this.selectT == "month") {
      this.startTimeStamp = new Date().getTime();
      this.endTimeStamp = new Date().getTime() + 3600 * 1000 * 24 * 30;
    } else if (this.selectT == "weekend") {
      if (this.isWeekend == true) {
        this.startTimeStamp = new Date().getTime();
        this.endTimeStamp =
          new Date().setHours(23, 59, 59, 999) +
          (7 - new Date().getDay()) * 3600 * 1000 * 24;
      } else {
        // 设置时间为今日0点，获取时间戳
        console.log(new Date().setHours(0, 0), "99999");
        this.startTimeStamp =
          new Date().setHours(0, 0, 0, 0) +
          (6 - new Date().getDay()) * 3600 * 1000 * 24;
        this.endTimeStamp =
          new Date().setHours(23, 59, 59, 999) +
          (7 - new Date().getDay()) * 3600 * 1000 * 24;
      }
    } else if (this.selectT === "appoint") {
      this.$nextTick(() => {
        let myCalendar = this.$refs["my-calendars"] as MyCalendar;
        if (myCalendar) {
          myCalendar.initData();
        }
      });
    }
  }
}
