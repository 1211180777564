
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  PullRefresh,
  Popover,
  Icon,
  Popup,
  Sticky,
  List,
  Image,
  DropdownMenu,
  DropdownItem,
} from "vant";
import CategoryList from "@/components/CategoryList.vue";
import { classForm } from "@/shims-video";
import toursList from "@/components/toursList.vue";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [Popover.name]: Popover,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    [List.name]: List,
    [Image.name]: Image,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    toursList,
    CategoryList,
  },
})
export default class Show extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  searchKeyword!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  choseTypeBtnText!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  caterId!: string;
  public refreshShow = false;
  public showPopover = false;
  public data = new Date().getTime() + "";
  public actions: dictionary.Codebook[] = [];
  public choose = "";
  public tourList: good.RecommendProductDto[] | undefined = [];
  public orderType = "";
  // 渠道分销默认是全部时间 ，演出页面默认是一个月
  public formData: classForm = {
    discountType: "BY_All",
    priceEnum: undefined,
    showType: "BY_All",
    startTimeStamp: this.isChannelDistribution ? 0 : new Date().getTime(),
    endTimeStamp: this.isChannelDistribution
      ? 0
      : new Date().getTime() + 3600 * 1000 * 24 * 30,
  };
  public scrollTop = -1;
  /* 
      组件复用，监听高级分类页tab切换
    */
  @Watch("caterId")
  caterIdChange(): void {
    /**
     * 虽然这里已经能拿到最新的 $route.params.categroyId
     * 但是新的值要到下一个 event loop 才能被 CategoryList 内的 Prop 接收到
     * 所以要加一个 $nextTick()
     */
    this.$nextTick(() => {
      this.initList();
    });
  }

  created(): void {
    this.recieveCodeBook();
  }
  mounted(): void {
    if (this.isChannelDistribution) {
      this.formData.startTimeStamp = 0;
      this.formData.endTimeStamp = 0;
    }
    this.initList();
    if (this.$refs.tours as toursList) {
      (this.$refs.tours as toursList).initSwiper();
    }
  }
  activated(): void {
    this.initList();
    if (this.$refs.tours as toursList) {
      (this.$refs.tours as toursList).initSwiper();
    }
  }
  // 初始化查询条件
  initList(): void {
    this.$nextTick(() => {
      let categoryList: CategoryList = this.$refs[
        "common-list"
      ] as CategoryList;
      if (categoryList) {
        categoryList.refresh();
      }
      this.getTourShow();
    });
  }

  /**
   * 根据查询表单查询数据
   */
  queryData(val: classForm): void {
    this.formData = val;
    this.initList();
  }

  // 获取分类文案
  recieveCodeBook(): void {
    if (this.actions.length == 0) {
      this.InterfaceCacheModule.getData({
        cacheKey: "performance.codeBook",
        apiMethod: this.$api.dictionaryApi.performance.codeBook,
        callback: ({ data }) => {
          this.choose = data.OrderRulesEnum[0].describe!;
          this.actions = data.OrderRulesEnum;
        },
      });
    }
  }

  onloadRefresh(): void {
    this.initList();
    setTimeout(() => {
      this.refreshShow = false;
    }, 2000);
  }

  onSelect(action: dictionary.Codebook): void {
    if (this.$route.name == "classify") {
      monitorEvent("ItemList_ChooseSort", "点击排序项", action.describe || ""); // 埋点：分类页，点击排序项
    }
    if (action.describe) {
      this.choose = action.describe;
    }
    if (action.code) {
      this.orderType = action.code;
    }
    // this.reset();
    this.initList();
  }

  // 单独选择时，排除对话框影响
  // reset(): void {
  //   this.formData = {};
  // }

  // 开启对话框
  onclickPop(): void {
    this.$emit("open-category-selector");
  }
  // 选择日历
  onclickCalendar(): void {
    this.$emit("open-calendar-selector");
  }

  // 封装巡演搜索
  getTourShow(): void {
    this.$api.goodApi.product.searchTours(
      {
        tour: true,
        subCategoryId: "",
        keyword: "",
        categoryId: this.$route.params.categroyId || this.caterId,
        flagshipStore: false,
        city: "",
        discountType: this.formData.discountType,
        priceEnum: this.formData.priceEnum,
        orderType: this.orderType as
          | "BY_START_DATE"
          | "BY_PRICE"
          | "BY_SHELVING_DATE"
          | undefined,
        page: 1,
        showType: this.formData.showType,
        size: 4,
        startTimeStamp: this.formData.startTimeStamp || undefined,
        endTimeStamp: this.formData.endTimeStamp || undefined,
      },
      ({ data }) => {
        if (data) {
          this.tourList = data;
        }
      }
    );
  }
}
