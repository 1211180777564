
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";

import SwiperCore, {
  Swiper,
  EffectCards,
  EffectCoverflow,
  Scrollbar,
  Autoplay,
  Navigation,
} from "swiper";
import { Image, Row, Col, Icon } from "vant";
SwiperCore.use([EffectCards, Autoplay, EffectCoverflow, Scrollbar, Navigation]);

@Component({
  components: {
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
  },
})
export default class MySwiper extends Mixins(Mixin) {
  public id = this.guid();
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  swiperList!: any;

  @Prop()
  public SwiperType!:
    | "slide"
    | "fade"
    | "cube"
    | "coverflow"
    | "flip"
    | "creative"
    | "cards";
  @Prop({ default: false })
  autoplay!: boolean;
  @Prop({ default: 1 })
  slidesPerView!: number;
  @Prop({ default: 0 })
  spaceBetween!: number;
  @Prop({ default: false })
  showScrollbar!: boolean;
  @Prop({ default: false })
  showArrow!: boolean;
  @Prop({ default: 0 })
  initialSlide!: number;
  @Prop()
  coverflowEffect!: Record<string, unknown>;
  @Prop()
  loop!: boolean;
  @Prop({ default: true })
  centeredSlides!: boolean;
  swiper: any = null;
  public playerOptions: ObjectMap = {
    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
    autoplay: false, //如果true,浏览器准备好时开始回放。
    muted: false, // 默认情况下将会消除任何音频。
    loop: false, // 导致视频一结束就重新开始。
    preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    language: "zh-CN",
    aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    sources: [
      {
        type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        src: "", //url地址
      },
    ],
    poster: "../../static/images/test.jpg", //你的封面地址
    // width: document.documentElement.clientWidth, //播放器宽度
    notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
    controlBar: {
      timeDivider: true,
      durationDisplay: true,
      remainingTimeDisplay: false,
      fullscreenToggle: true, //全屏按钮
    },
  };

  mounted(): void {
    // 时间设置变短，让会员轮播图快速加载
    setTimeout(() => {
      this.initSwiper();
    }, 50);
  }
  initSwiper(): void {
    let effect:
      | "slide"
      | "fade"
      | "cube"
      | "coverflow"
      | "flip"
      | "creative"
      | "cards" = this.SwiperType;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    this.swiper = new Swiper(".mySwiper_" + this.id, {
      effect: effect,
      grabCursor: true,
      loop: this.loop, //开启无限循环时需要用realIndex
      autoplay: this.autoplay,
      slidesPerView: this.slidesPerView,
      spaceBetween: this.spaceBetween,
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      on: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap: function (swiper: any, event: any) {
          vm.$emit("swiperClick", swiper, event);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slideChange: function (swiper: any) {
          vm.$emit("slideChange", swiper);
        },
        reachEnd: function (swiper: any) {
          vm.$emit("slideChangeTransitionEnd", swiper);
          // alert(swiper.activeIndex); //切换结束时，告诉我现在是第几个slide
        },
      },
      coverflowEffect: this.coverflowEffect
        ? this.coverflowEffect
        : {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      centeredSlides: this.centeredSlides,
      initialSlide: this.initialSlide,
      observe: true,
      observeParents: true,
    });
  }
  swiperSlideTo(index: number): void {
    /**
     * 控制Swiper切换到指定slide
     * @param  index:指定将要切换到的slide的索引
     * @param  speed:	切换速度(单位ms)
     * @param  runCallbacks:设置为false时不会触发transition回调函数
     */
    this.swiper.slideTo(index, 1000, false);
  }
}
