
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import MySwipers from "@/components/MySwiper.vue";
import { Image, Row, Col, Icon, Tabs, Tab } from "vant";
import { BillboardInfoDtos } from "@/shims-video";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    MySwipers,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
})
export default class HotRanks extends Mixins(Mixin) {
  @Prop()
  public rankList!: BillboardInfoDtos;
  @Prop({ default: 0 })
  public rankIndex!: number;
  type = "slide";
  toursList: any = [];
  coverflowEffect = {
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  };
  active = "0";
  rankListTitlelist = [1, 2];
  gotoRankDetails(): void {
    let rankList = this.rankList;
    monitorEvent("Home_ClickPolularList", "点击榜单详情", rankList.title || ""); // 埋点：点击榜单详情
    // this.$router.push(`/rank-detail/${rankList.billboardId}/${this.rankIndex}`);
    this.$router.push(`/rank-list`);
  }
}
