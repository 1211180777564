
import { Component, Mixins } from "vue-property-decorator";
import { NavBar, Swipe, SwipeItem, Image, Row, List } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import wx from "weixin-js-sdk";
import Ticketltem from "@/components/Tickets/Ticketltem.vue";
import DefaultPopup from "@/views/LimitActivities/components/DefaultPopup.vue";
import LimitSwiper from "@/views/LimitActivities/components/LimitSwiper.vue";
import PerHead from "@/views/TicketOrder/PerOrderDetails/components/PerHead.vue";
import FooterLine from "@/views/Mall/MallPage/FooterLine.vue";
import LimitNull from "@/views/LimitActivities/components/LimitNull.vue";
declare type ChannelType =
  | "plat_h5"
  | "plat_ios"
  | "plat_android"
  | "plat_wechat_miniapp";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Row.name]: Row,
    [List.name]: List,
    Ticketltem,
    DefaultPopup,
    LimitSwiper,
    PerHead,
    FooterLine,
    LimitNull,
  },
})
export default class FlashSaleDemo extends Mixins(Mixin) {
  scrollTop = 91;
  activeIndex = 1;
  isFixed = false;
  showPopup = false;
  id = ""; //活动id
  activeId = ""; // 点击时间轴当前活动id
  info: cms.ActivityInfoDto = { menuType: "" };
  timeList: cms.ActivityLimitCouponTimeDto[] = []; //时间轴列表
  activityState = "3"; //点击时间轴当前活动状态 默认3 已结束
  num = 0;
  currentActivity: cms.flashSaleProjectDto[] = [];
  page = 0;
  size = 10;
  pages = 1;
  loading = false;
  finished = false;
  isShowBack = true;

  updateData(): void {
    this.getAllMethods();
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getAllMethods();
      }
    }
  }
  getAllMethods(): void {
    this.isShowBack = this.$route.query.isTab ? false : true; //是否是首页的tab,是不展示返回按钮
    let div = document.getElementById("l-h-coupon") as HTMLElement;
    div.addEventListener("scroll", this.handleScroll);
    this.getTimeList();
  }

  beforeDestroy(): void {
    let div = document.getElementById("l-h-coupon") as HTMLElement;
    div.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("visibilitychange", this.refreshPage);
  }

  //分享
  shareDate(): void {
    let url = `${location.origin}${location.pathname}#/flash-sale`;
    let title = this.info.activityName || "";
    let shareDescribe = this.info.shareDescription || "";
    let shareImg = this.info.shareImageUrl || "";
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
    );
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
  close(): void {
    this.showPopup = false;
  }

  /*
   * 限时抢购活动时间轴
   */
  getTimeList(): void {
    this.$api.cmsApi.flashSale.getFlashSaleActivityTimes(({ data }) => {
      if (data.length > 0) {
        this.timeList = data;
        let result = this.dealTimeList(data);
        this.activityState = result.activityState;
        this.num = result.num;
        this.activeIndex = result.activeIndex;
        this.activeId = result.activeId;
        this.allMethod();
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "1");
          window.startShare = this.shareDate;
        }
      }
    });
  }

  //Promise并发 2中方法同时执行
  allMethod(): void {
    this.page = 1;
    Promise.all([this.getInfo(), this.getShowList()])
      .then((res) => {
        if (res.length > 0) {
          this.info = res[0] || { menuType: "" };
          this.currentActivity = res[1].records || [];
          this.pages = res[1].pages || 1;
        }
      })
      .catch((error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      });
  }

  /*
   * 获取信息
   */
  getInfo(): Promise<cms.ActivityInfoDto> {
    return new Promise((resolve, reject) => {
      this.$api.cmsApi.activityInfo.findActivityBaseInfoById(
        this.activeId,
        ({ data }) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /*
   * 获取票务列表
   */
  getShowList(): Promise<cms.Page<cms.flashSaleProjectDto>> {
    return new Promise((resolve, reject) => {
      this.$api.cmsApi.flashSale.queryFlashSaleActivityProductInfo(
        {
          activityId: this.activeId,
          page: this.page,
          size: this.size,
        },
        ({ data }) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  /*
   * 去抢购详情
   */
  goLimitDetail(activityId?: string, productId?: string): void {
    if (this.activityState === "1" || this.activityState === "2") {
      this.goSnapUp(activityId, productId);
    }
  }

  handleScroll(): void {
    let ob = this.$refs.lMain as HTMLElement;
    this.scrollTop = ob.getBoundingClientRect().top;
    if (this.scrollTop <= 90) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  /*
   * 点击抢票时间
   */
  activeVolume(data: {
    item: cms.ActivityLimitCouponTimeDto;
    index: number;
  }): void {
    this.currentActivity = [];
    this.page = 0;
    this.activeId = data.item.activityId!;
    this.activeIndex = data.index;
    this.activityState = data.item.activityState!;
    this.loading = false;
    this.finished = false;
    this.allMethod();
  }

  dealSratelName(activityState: string): string {
    let title = "已结束";
    if (activityState === "0") {
      title = "即将开始";
    } else if (activityState === "1" || activityState === "2") {
      title = "立即抢购";
    }
    return title;
  }

  onLoad(): void {
    this.downInfo();
  }

  downInfo(): void {
    this.page++;
    this.loading = true;
    this.$api.cmsApi.flashSale.queryFlashSaleActivityProductInfo(
      {
        activityId: this.activeId,
        page: this.page,
        size: this.size,
      },
      ({ data }) => {
        this.loading = false;
        this.pages = data.pages || 1;
        if (data.records && data.records.length > 0) {
          this.currentActivity = this.currentActivity.concat(data.records);
        }
        if (this.page >= Number(this.pages)) {
          this.finished = true;
        }
      },
      (error) => {
        this.loading = false;
        error && this.$toast(error);
      }
    );
  }
}
