
import { Component, Mixins, Prop } from "vue-property-decorator";
import { NavBar, Swipe, SwipeItem, Image, Row } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.less";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Row.name]: Row,
  },
})
export default class LimitSwiper extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public timeList!: cms.ActivityLimitCouponTimeDto[]; //列表
  @Prop({
    type: Number,
    default: 0,
  })
  public num!: number; //展示第一个活动位置的索引
  @Prop({
    type: Number,
    default: 1,
  })
  public bgType!: number; //背景图
  @Prop({
    type: Number,
    default: 0,
  })
  public activeIndex!: number; //点击的索引
  mounted(): void {
    this.initSwiper();
  }
  /*
   * 点击哪一个活动时间
   */
  activeVolume(item: cms.ActivityLimitCouponTimeDto, index: number): void {
    let data = {
      item,
      index,
    };
    this.$emit("activeVolume", data);
  }
  /*
   * 限时抢购轮播
   */
  initSwiper(): void {
    new Swiper(".swiper1", {
      slidesPerView: "auto",
      // slidesPerView: 3, //设置slider容器能够同时显示的slides(展示个数)数量
      autoplay: false,
      loop: false,
      initialSlide: this.num, //设定初始化时slide的索引,Swiper默认初始化时显示第一个slide，有时想初始化时直接显示其他slide
      centeredSlides: false, //是否设置当前索引 Slide居中后
      // spaceBetween: 30, //slider容器间距默认单位px
      on: {
        // click: function (swiper: any, event: any) {
        //   console.log(77, vm.num, 88, event);
        // },
      },
    });
  }

  /*
   * 处理时间
   */
  dealStartTime(item: cms.ActivityLimitCouponTimeDto): string {
    let time = "";
    if (item.activityDateFlag === "0") {
      time = item.activityStartTime!;
    } else if (item.activityDateFlag === "1") {
      time = "明天" + " " + item.activityStartTime!;
    } else {
      time = item.activityDate + " " + item.activityStartTime!;
    }
    return time;
  }
}
