import { render, staticRenderFns } from "./toursList.vue?vue&type=template&id=d0cf2dfa&scoped=true"
import script from "./toursList.vue?vue&type=script&lang=ts"
export * from "./toursList.vue?vue&type=script&lang=ts"
import style0 from "./toursList.vue?vue&type=style&index=0&id=d0cf2dfa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0cf2dfa",
  null
  
)

export default component.exports