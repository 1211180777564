
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, Icon, Divider } from "vant";
import { Recommends } from "@/shims-video";
import BottomLoadingLine from "@/components/BottomLoadingLine.vue";
// import { keyword715, hasKeyword, has715Label } from "@/utils/label715";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    BottomLoadingLine,
  },
})
export default class Recommend extends Mixins(Mixin) {
  currentPage = 1; // 滚动分页，当前在第几页
  pageFinished = false; // 滚动分页，是否已经没有数据了
  fixed: Recommends = {};
  wfHeight = "";
  mark = false;
  stopLoad = false;
  final: Array<Recommends> = [];
  recommendKey = 1;
  // keyword715 = keyword715;
  get final2(): Array<Recommends> {
    let final2: Array<Recommends> = this.final;
    this.$nextTick(() => {
      if (this.fixed.advertUrl1 && final2.length > 0 && !this.mark) {
        if (this.secondHigher()) {
          final2.splice(3, 0, this.fixed);
        } else {
          final2.splice(2, 0, this.fixed);
        }
        this.mark = true;
      }
    });
    return final2;
  }

  refreshData(): void {
    this.fixAd();
    this.currentPage = 1;
    if (this.currentPage == 1) {
      this.recommendKey++;
    }
    this.recieveRecommend();
  }

  /**
   * 添加滚动事件
   */
  addScroll(): void {
    window.addEventListener("scroll", this.watchScroll, true);
  }

  /**
   * 移除滚动事件
   */
  removeScroll(): void {
    window.removeEventListener("scroll", this.watchScroll, true);
  }

  // 滚动分页，屏幕滚动事件回调
  watchScroll(): void {
    // 滚动条到底部且没有完成搜索（还有更多数据）
    if (this.isWindowBottom() && !this.pageFinished) {
      ++this.currentPage;
      this.recieveRecommend();
    }
  }

  /**
   * 跳转详情页
   *
   * @param item 项目信息
   */
  goProDetail(item: Recommends): void {
    this.$emit("go-to-detail", item);
  }

  /**
   * 第 2 个元素更高
   */
  secondHigher(): boolean {
    let final = this.final;
    if (!final || final.length < 2) {
      return false;
    }
    let productId1 = final[0].productId || -1;
    let productId2 = final[1].productId || -1;
    if (this.$refs[productId1] && this.$refs[productId2]) {
      let card1 = this.$refs[productId1] as Array<HTMLElement>;
      let card2 = this.$refs[productId2] as Array<HTMLElement>;
      //第一个元素与第二个元素高度相对，那么跟第一个元素高度>第二个元素高度的排列方式是一样的
      if (card1[0].offsetHeight == card2[0].offsetHeight) {
        return true;
      }
      return card1[0].offsetHeight < card2[0].offsetHeight;
    }
    return false;
  }

  // 获取推荐
  recieveRecommend(): void {
    let currentPage = this.currentPage;
    // if (currentPage == 1) {
    //   // 查询第一页，肯定不能拼接啊
    //   this.final = [];
    // }
    this.InterfaceCacheModule.getData({
      cacheKey: "product.getRecommendList",
      apiMethod: this.$api.goodApi.product.getRecommendList,
      params: [
        {
          page: currentPage,
          size: 20,
          sort: "HOT_RECOMMEND",
        },
      ],
      callback: ({ data }) => {
        let final = this.final;
        if (currentPage == 1) {
          this.mark = false;
          final = data.records!;
        } else {
          final.push(...data.records!);
        }
        this.setPageFinished(currentPage, data);
        this.final = final;
        // this.$forceUpdate();
      },
    });
  }

  // 滚动分页，根据返回的数据设置【是否查询完成】的标志
  setPageFinished(currentPage: number, data: good.Page<unknown>): void {
    let pages = 0;
    // 终止加载的标志
    if (data) {
      pages = data.pages || 0;
    }
    this.pageFinished = currentPage >= pages;
  }

  // 固定广告获取
  fixAd(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "advert.cityAdverts",
      apiMethod: this.$api.cmsApi.advert.cityAdverts,
      params: ["APP_RECOMMEND_ADVERT"],
      callback: ({ data }) => {
        this.fixed = data && data.length > 0 ? data[0] : {};
        this.fixed.isfix = true;
      },
    });
  }
  // hasKeyword(name: string | undefined, keyword: string) {
  //     return hasKeyword(name, keyword);
  // }
  // has715Label(name: string | undefined) {
  //     return has715Label(name);
  // }
}
