
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Grid, GridItem, Icon, Image } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    [Image.name]: Image,
  },
})
export default class MetalRegion extends Mixins(Mixin) {
  columnCount = 0; // 每行展示多少项
  metalRegions: Array<cms.ColumnDetail> = []; // 首页金刚区用于展示的项
  // hasMore = false; // 金刚区是否有更多
  themeData: cms.ThemeDto = {};

  /**
   * 刷新金刚区数据,接入cms主题
   */
  refreshData(): void {
    this.resetData();
    this.updateData();
  }
  /**
   * 重置金刚区数据
   */
  resetData(): void {
    this.columnCount = 0;
    /**
     * 为了满足断网之后 ， 还能将金刚区最近的数据 ，缓存一段时间的需求，将金刚区数组置空 放到了如下 updateData() 方法中。
     * 如果影响到了其他地方，请继续修改。。。
     */
  }

  /**
   * 更新金刚区数据,接入cms主题
   */
  updateData(): void {
    let VajraAll: cms.VajraDistrictDto | undefined = {};
    if (
      this.HomeDataModule.theme &&
      this.HomeDataModule.theme.themeVajraDistrict
    ) {
      this.metalRegions = []; // 转移到了这里
      VajraAll = this.HomeDataModule.theme.themeVajraDistrict;
      this.handleVajra(VajraAll);
    } else {
      this.InterfaceCacheModule.getData({
        cacheKey: "commonInfo.vajraDistricts",
        apiMethod: this.$api.cmsApi.commonInfo.vajraDistricts,
        params: ["TICKET"],
        callback: ({ data }) => {
          this.metalRegions = []; // 转移到了这里
          this.handleVajra(data);
        },
      });
    }
  }

  // 统一进行处理
  handleVajra(VajraAll: cms.VajraDistrictDto | undefined): void {
    if (VajraAll) {
      let columnCount = VajraAll.columnCount || 0;
      if (columnCount > 0) {
        this.columnCount = columnCount;
        let vajraDistricts = VajraAll.vajraDistricts || [];
        let limit = columnCount * (VajraAll.rowCount || 0);
        if (vajraDistricts.length > limit) {
          this.metalRegions = vajraDistricts.slice(0, limit);
          // this.hasMore = true;
        } else {
          this.metalRegions = vajraDistricts;
        }
      }
    }
  }
  /**
   * 点击金刚区选项
   *
   * @param item 金刚区信息
   */
  clickMetalRegion(item: cms.ColumnDetail, index: number): void {
    monitorEvent("ClickChannel", "点击栏目名称", item.columnName || ""); // 埋点：点击栏目名称
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
    if (item.columnName == "更多") {
      this.goToMore();
    }
  }

  /**
   * 跳转至金刚区的更多页面
   */
  goToMore(): void {
    monitorEvent("ClickAllChannel", "点击更多栏目"); // 埋点：点击更多栏目
    this.$router.push("/more");
  }
}
