
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import MySwiper from "@/components/MySwiper.vue";
import { Image } from "vant";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: { MySwiper, [Image.name]: Image },
})
export default class toursList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public tourList!: Array<good.RecommendProductDto>;
  public type = "coverflow";
  public coverflowEffect = {
    rotate: 40,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  };
  public auto = "auto";
  id = "";
  @Watch("toursList", { deep: true })
  changeTourList(val: Array<good.RecommendProductDto>): void {
    this.tourList = val;
  }
  initSwiper(): void {
    this.id = this.guid();
    new Swiper(".mySwiper_" + this.id, {
      slidesPerView: 2,
      // spaceBetween: 10,
      slidesOffsetAfter: 30,
      autoplay: false,
      loop: false,
      on: {
        // click: function (swiper: any, event: any) {
        //   console.log(77, vm.num, 88, event);
        // },
      },
    });
  }

  goProDetail(item: good.RecommendProductDto): void {
    if (this.$route.name == "classify") {
      monitorEvent(
        "ItemList_ClickTour",
        "点击一个巡演",
        item.productNameShort || ""
      ); // 埋点：分类页，点击一个巡演
    }
    this.$router.push(`/tour/${item.tourId}`);
  }
}
