
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image, Row, Col } from "vant";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
  },
})
export default class Ticketltem extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: 1,
  })
  public type!: number; //(1.个人中心，2.订单详情,3.演出详情卷,4.北京文慧卷)

  @Prop({
    type: Number,
    default: 1,
  })
  public status!: number; //(1.未使用（领取），2.已使用，已作废，3.领光,已领)

  @Prop({
    type: String,
    default: "",
  })
  public parValue!: string; //金额

  @Prop({
    type: Boolean,
    default: false,
  })
  public isVip!: boolean; //订单详情场景是否点击

  @Prop({
    type: String,
    default: "",
  })
  public orderAmount!: string; //(订单金额类型为2)

  @Prop({
    type: String,
    default: "",
  })
  public shopName!: string; //剧院名称

  @Prop({
    type: String,
    default: "",
  })
  public couponName!: string; //优惠卷名称

  @Prop({
    type: [String, Number],
    default: "",
  })
  public batchClass!: [string, number]; //优惠券类型（满减、打折）

  @Prop({
    type: Number,
    default: 0,
  })
  public effectiveType!: number; //生效类型(1：时间段，2：有效时间天数)

  @Prop({
    type: String,
    default: "",
  })
  public effectiveDate!: string; //生效日期

  @Prop({
    type: String,
    default: "",
  })
  public btnName!: string; //右侧按钮名称(领取，去使用)

  @Prop({
    type: String,
    default: "",
  })
  public quantity!: string; //票数量
  @Prop({
    type: Boolean,
    default: false,
  })
  ifcheck!: boolean; //订单详情场景是否点击

  @Watch("ifcheck")
  changeCheck(val: boolean): void {
    this.check = val;
  }

  public check = false;

  created(): void {
    this.check = this.ifcheck;
  }
  /*
   * 点击
   */
  onClick(): void {
    this.check = !this.check;
    this.$emit("onClick", this.check);
  }
  /*
   * 去详情
   */
  goDetail(): void {
    this.$emit("goDetail");
  }

  public discountValue = "";
  @Watch("batchClass")
  changeBatchClass(val: [string, number]): void {
    if (Number(val) === 6) {
      let value = Number(this.parValue.replace("￥", ""));
      this.discountValue = (value * 100) / 10 + "";
    }
  }
}
