
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  PullRefresh,
  Popover,
  Icon,
  Popup,
  Sticky,
} from "vant";
import { ThisEnumerationCodes } from "@/shims-video";
import MyCalendar from "@/views/Classify/components/Calender/MyCalendar.vue";
import ScreenTime from "@/views/Mine/MoreServices/Club/Components/ScreenTime.vue";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [Popover.name]: Popover,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    MyCalendar,
    ScreenTime,
  },
})
export default class CategorySelector extends Mixins(Mixin) {
  @Prop({ type: Boolean, default: false })
  hideCoupon!: boolean;
  @Prop({ type: Boolean, default: false })
  hidePrice!: boolean;

  showSelector = false;

  // public choseTypeBtnText = "全部时间";

  open(): void {
    this.showSelector = true;
  }
  // 关闭对话框
  onclosePop(): void {
    this.showSelector = false;
  }
  /*
  时间筛选全部活动全部活动
  */
  getSelectTime(form: {
    startTimeStamp: Date;
    endTimeStamp: Date;
    type: string;
  }): void {
    this.$emit("select", form);
    this.showSelector = false;
  }
}
